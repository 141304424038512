require('./bootstrap');

(function ($) {
  "use strict";

  /* FOR DESKTOP ONLY */
  if (window.matchMedia("(min-width: 992px)").matches) {

    /* LIEN PREMIER NIVEAU AVEC DROPDOWN CLICKABLE */
    $('.dropdown-toggle').removeAttr('data-toggle');

    /* DISPLAY BS4 TOOLTIP */
    $('[data-toggle="tooltip"]').tooltip();

    /* GO TOP */
    var btn = $('#cotiTop');
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 300) {
        btn.show(10).animate({
          right: '12px'
        }, 0);
      } else {
        btn.animate({
          right: '-48px'
        }, 0);
      }
    });
  };


  /* ADD LINK ARROUND MAIN CONTENT IMAGES */
  $('#main img').each(function () {
    var currentImg = $(this);
    currentImg.wrap("<a href='" + currentImg.attr("src") + "' data-fancybox='gallery'></a>");
  });

  /* FANCYBOX OPTIONS */
  $('[data-fancybox]').fancybox({
    protect: true,
    transitionEffect: "slide",
    wheel: "auto",
    caption: function( instance, item ) {
      return $(this).find('img').attr('alt');
    },
    buttons: [
      "zoom",
      "close"
    ],
  });

})(jQuery);
